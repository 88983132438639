const toggleMenu = () => {
    $(".menu-toggle").on("click", function () {
        $(".menu-wrap").slideToggle();
    });
};

$("header").sticky({
    topSpacing: 0,
    zIndex: 50,
});

if ($(window).width() < 1025) {
    $(".has-sub .toggle").on("click", function () {
        $(this).next().slideToggle();
        $(this).toggleClass("fa-chevron-down fa-chevron-up");
    });
} else {
}

const useHeader = () => {
    return {
        toggleMenu,
    };
};

export default useHeader;
