var lazyLoadInstance = new LazyLoad({
    // Your custom settings go here
});

import useHeader from "./partial/Header";
import useSlide from "./partial/Slide";

const { toggleMenu } = useHeader();

jQuery(function () {
    AOS.init({
        duration: 700,
        disable: "mobile",
    });
    toggleMenu();
    collapseComponent();
    useSlide();
});

function collapseComponent() {
    $(".accordion-item").each(function () {
        let _this = $(this);
        let otherList = $(".accordion-item").not(_this);
        let bodyItem = _this.find(".accordion-body");
        let otherBody = $(".accordion-body").not($(bodyItem));

        _this.on("click", function () {
            $(bodyItem).slideToggle();
            $(otherBody).slideUp();
            _this.toggleClass("active");
            $(otherList).removeClass("active");
        });
    });
}
