const documentSliderThumb = new Swiper(
    ".document-slider-thumb .swiper-container",
    {
        slidesPerView: 3,
        spaceBetween: 32,
        observer: true,
        observeParents: true,
        slideToClickedSlide: true,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
    }
);

const documentSliderImages = new Swiper(
    ".document-slider-images .swiper-container",
    {
        observer: true,
        observeParents: true,
        slideToClickedSlide: true,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        effect: "fade",
        fadeEffect: { crossFade: true },
        navigation: {
            prevEl: ".document-slider-thumb .swiper-prev",
            nextEl: ".document-slider-thumb .swiper-next",
        },
        thumbs: {
            swiper: documentSliderThumb,
        },
        on: {
            slideChange: function () {
                let activeIndex = this.activeIndex + 1;

                let nextSlide = $(
                    `.document-slider-thumb .swiper-slide:nth-child(${
                        activeIndex + 1
                    })`
                );
                let prevSlide = $(
                    `.document-slider-thumb .swiper-slide:nth-child(${
                        activeIndex - 1
                    })`
                );

                if (nextSlide && !nextSlide.hasClass("swiper-slide-visible")) {
                    this.thumbs.swiper.slideNext();
                } else if (
                    prevSlide &&
                    !prevSlide.hasClass("swiper-slide-visible")
                ) {
                    this.thumbs.swiper.slidePrev();
                }
            },
        },
        breakpoints: {
            1024: {
                effect: "slide",
            },
        },
    }
);

const useSlide = () => {
    return {};
};

export default useSlide;
